export const PAGE_ROUTES = {
    AUTH_HOME: "/auth/",
    AUTH_VERIFY_BASE: "/auth/verify/",
    AUTH_REGISTER: "/auth/register/",
    HOME: "/",
    DISCOVER: "/discover/",
    BUSINESS_DETAILS: "/businesses/{id}/",
    BUSINESS_DEALS: "/businesses/{id}/?tab=deals",
    BUSINESS_MENU: "/businesses/{id}/?tab=menu",
    REWARD_REDEEM: "/businesses/{business_id}/deals/{deal_id}/rewards/{id}/",
    PRO: "/pro/",
    PRO_BUSINESSES: "/pro/?tab=businesses",
    PRO_HISTORY: "/pro/?tab=history",
    PRO_CLAIM: "/pro/claim/",
    CONTESTS: "/contests/",
    CONTESTS_RECENT: "/contests/?tab=recent",
    CONTESTS_HISTORICAL: "/contests/?tab=history",
    CONTESTS_PREFERENCES: "/contests/preferences/",
    ACCOUNT: "/account/",
    ACCOUNT_PROFILE: "/account/profile/",
    ACCOUNT_FAVORITES: "/account/favorites/",
    ACCOUNT_REFERRALS: "/account/referrals/",
    ACCOUNT_NOTIFICATIONS: "/account/notifications/",
    ACCOUNT_NOTIFICATION_PREFERENCES: "/account/notifications/preferences/",
    ACCOUNT_OPERATORS: "/account/operators/",
    ACCOUNT_HELP: "/account/help/",
    ACCOUNT_LEGAL: "/account/legal/",
    ACCOUNT_MANAGE: "/account/manage/",
    ACCOUNT_MANAGE_PAUSE: "/account/manage/pause/",
    ACCOUNT_MANAGE_CANCEL: "/account/manage/cancel/",
    ACCOUNT_MANAGE_CANCEL_CONFIRM: "/account/manage/cancel/confirm/",
    ACCOUNT_SECRETS: "/account/secrets/",
    ACCOUNT_GIFTING: "/account/gifts/",
    ACCOUNT_GIFTING_CREATE: "/account/gifts/create/",
    ACCOUNT_GIFTING_CONFIRMATION: "/account/gifts/confirmation/",
    GIVEAWAY_DETAILS: "/giveaways/{id}/",
    ANNOUNCEMENT_DETAILS: "/announcements/{id}/",
}
