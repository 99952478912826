/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HostCodeValidation,
  HostCodeValidationResult,
} from '../models';
import {
    HostCodeValidationFromJSON,
    HostCodeValidationToJSON,
    HostCodeValidationResultFromJSON,
    HostCodeValidationResultToJSON,
} from '../models';

export interface HostsValidateCodeCreateRequest {
    HostCodeValidation: HostCodeValidation;
}

/**
 * HostsApi - interface
 * 
 * @export
 * @interface HostsApiInterface
 */
export interface HostsApiInterface {
    /**
     * 
     * @param {HostCodeValidation} HostCodeValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostsApiInterface
     */
    hostsValidateCodeCreateRaw(requestParameters: HostsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HostCodeValidationResult>>;

    /**
     */
    hostsValidateCodeCreate(requestParameters: HostsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HostCodeValidationResult>;

}

/**
 * 
 */
export class HostsApi extends runtime.BaseAPI implements HostsApiInterface {

    /**
     */
    async hostsValidateCodeCreateRaw(requestParameters: HostsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HostCodeValidationResult>> {
        if (requestParameters.HostCodeValidation === null || requestParameters.HostCodeValidation === undefined) {
            throw new runtime.RequiredError('HostCodeValidation','Required parameter requestParameters.HostCodeValidation was null or undefined when calling hostsValidateCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/hosts/validate-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostCodeValidationToJSON(requestParameters.HostCodeValidation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostCodeValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async hostsValidateCodeCreate(requestParameters: HostsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HostCodeValidationResult> {
        const response = await this.hostsValidateCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
