/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeValidationResultMetadata } from './GiftCodeValidationResultMetadata';
import {
    GiftCodeValidationResultMetadataFromJSON,
    GiftCodeValidationResultMetadataFromJSONTyped,
    GiftCodeValidationResultMetadataToJSON,
} from './GiftCodeValidationResultMetadata';

/**
 * 
 * @export
 * @interface GiftCodeValidationResult
 */
export interface GiftCodeValidationResult {
    /**
     * 
     * @type {boolean}
     * @memberof GiftCodeValidationResult
     */
    code_valid: boolean;
    /**
     * 
     * @type {string}
     * @memberof GiftCodeValidationResult
     */
    error_message: string;
    /**
     * 
     * @type {GiftCodeValidationResultMetadata}
     * @memberof GiftCodeValidationResult
     */
    metadata: GiftCodeValidationResultMetadata | null;
}

/**
 * Check if a given object implements the GiftCodeValidationResult interface.
 */
export function instanceOfGiftCodeValidationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code_valid" in value;
    isInstance = isInstance && "error_message" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function GiftCodeValidationResultFromJSON(json: any): GiftCodeValidationResult {
    return GiftCodeValidationResultFromJSONTyped(json, false);
}

export function GiftCodeValidationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GiftCodeValidationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code_valid': json['code_valid'],
        'error_message': json['error_message'],
        'metadata': GiftCodeValidationResultMetadataFromJSON(json['metadata']),
    };
}

export function GiftCodeValidationResultToJSON(value?: GiftCodeValidationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code_valid': value.code_valid,
        'error_message': value.error_message,
        'metadata': GiftCodeValidationResultMetadataToJSON(value.metadata),
    };
}

