/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RedemptionCodeType = {
    referral: 'referral',
    host: 'host',
    fundraiser: 'fundraiser',
    gift: 'gift'
} as const;
export type RedemptionCodeType = typeof RedemptionCodeType[keyof typeof RedemptionCodeType];


export function RedemptionCodeTypeFromJSON(json: any): RedemptionCodeType {
    return RedemptionCodeTypeFromJSONTyped(json, false);
}

export function RedemptionCodeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedemptionCodeType {
    return json as RedemptionCodeType;
}

export function RedemptionCodeTypeToJSON(value?: RedemptionCodeType | null): any {
    return value as any;
}

