/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GiftCodeMetadata } from './GiftCodeMetadata';
import {
    GiftCodeMetadataFromJSON,
    GiftCodeMetadataFromJSONTyped,
    GiftCodeMetadataToJSON,
} from './GiftCodeMetadata';

/**
 * 
 * @export
 * @interface GiftCodeValidationResultMetadata
 */
export interface GiftCodeValidationResultMetadata {
    /**
     * 
     * @type {string}
     * @memberof GiftCodeValidationResultMetadata
     */
    sender_name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GiftCodeValidationResultMetadata
     */
    sender_is_anonymous: boolean;
    /**
     * 
     * @type {number}
     * @memberof GiftCodeValidationResultMetadata
     */
    gift_credit_months: number;
    /**
     * 
     * @type {string}
     * @memberof GiftCodeValidationResultMetadata
     */
    gift_credit_amount: string;
}

/**
 * Check if a given object implements the GiftCodeValidationResultMetadata interface.
 */
export function instanceOfGiftCodeValidationResultMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sender_name" in value;
    isInstance = isInstance && "sender_is_anonymous" in value;
    isInstance = isInstance && "gift_credit_months" in value;
    isInstance = isInstance && "gift_credit_amount" in value;

    return isInstance;
}

export function GiftCodeValidationResultMetadataFromJSON(json: any): GiftCodeValidationResultMetadata {
    return GiftCodeValidationResultMetadataFromJSONTyped(json, false);
}

export function GiftCodeValidationResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GiftCodeValidationResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sender_name': json['sender_name'],
        'sender_is_anonymous': json['sender_is_anonymous'],
        'gift_credit_months': json['gift_credit_months'],
        'gift_credit_amount': json['gift_credit_amount'],
    };
}

export function GiftCodeValidationResultMetadataToJSON(value?: GiftCodeValidationResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender_name': value.sender_name,
        'sender_is_anonymous': value.sender_is_anonymous,
        'gift_credit_months': value.gift_credit_months,
        'gift_credit_amount': value.gift_credit_amount,
    };
}

