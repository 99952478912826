/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Gift,
  GiftCheckoutSessionURL,
  GiftCodeValidation,
  GiftCodeValidationResult,
  GiftReceiptURL,
} from '../models';
import {
    GiftFromJSON,
    GiftToJSON,
    GiftCheckoutSessionURLFromJSON,
    GiftCheckoutSessionURLToJSON,
    GiftCodeValidationFromJSON,
    GiftCodeValidationToJSON,
    GiftCodeValidationResultFromJSON,
    GiftCodeValidationResultToJSON,
    GiftReceiptURLFromJSON,
    GiftReceiptURLToJSON,
} from '../models';

export interface GiftsCheckoutSessionUrlRetrieveRequest {
    id: string;
}

export interface GiftsCreateRequest {
    Gift: Gift;
}

export interface GiftsDestroyRequest {
    id: string;
}

export interface GiftsReceiptUrlRetrieveRequest {
    id: string;
}

export interface GiftsRetrieveRequest {
    id: string;
}

export interface GiftsUpdateRequest {
    id: string;
    Gift: Gift;
}

export interface GiftsValidateCodeCreateRequest {
    GiftCodeValidation: GiftCodeValidation;
}

/**
 * GiftsApi - interface
 * 
 * @export
 * @interface GiftsApiInterface
 */
export interface GiftsApiInterface {
    /**
     * 
     * @param {string} id A unique integer value identifying this Gift.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsCheckoutSessionUrlRetrieveRaw(requestParameters: GiftsCheckoutSessionUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftCheckoutSessionURL>>;

    /**
     */
    giftsCheckoutSessionUrlRetrieve(requestParameters: GiftsCheckoutSessionUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftCheckoutSessionURL>;

    /**
     * 
     * @param {Gift} Gift 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsCreateRaw(requestParameters: GiftsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>>;

    /**
     */
    giftsCreate(requestParameters: GiftsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift>;

    /**
     * 
     * @param {string} id A unique integer value identifying this Gift.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsDestroyRaw(requestParameters: GiftsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    giftsDestroy(requestParameters: GiftsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>>;

    /**
     */
    giftsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>>;

    /**
     * 
     * @param {string} id A unique integer value identifying this Gift.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsReceiptUrlRetrieveRaw(requestParameters: GiftsReceiptUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftReceiptURL>>;

    /**
     */
    giftsReceiptUrlRetrieve(requestParameters: GiftsReceiptUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftReceiptURL>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsReceivedListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>>;

    /**
     */
    giftsReceivedList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>>;

    /**
     * 
     * @param {string} id A unique integer value identifying this Gift.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsRetrieveRaw(requestParameters: GiftsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>>;

    /**
     */
    giftsRetrieve(requestParameters: GiftsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsSentListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>>;

    /**
     */
    giftsSentList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>>;

    /**
     * 
     * @param {string} id A unique integer value identifying this Gift.
     * @param {Gift} Gift 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsUpdateRaw(requestParameters: GiftsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>>;

    /**
     */
    giftsUpdate(requestParameters: GiftsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift>;

    /**
     * 
     * @param {GiftCodeValidation} GiftCodeValidation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftsApiInterface
     */
    giftsValidateCodeCreateRaw(requestParameters: GiftsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftCodeValidationResult>>;

    /**
     */
    giftsValidateCodeCreate(requestParameters: GiftsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftCodeValidationResult>;

}

/**
 * 
 */
export class GiftsApi extends runtime.BaseAPI implements GiftsApiInterface {

    /**
     */
    async giftsCheckoutSessionUrlRetrieveRaw(requestParameters: GiftsCheckoutSessionUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftCheckoutSessionURL>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giftsCheckoutSessionUrlRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/{id}/checkout-session-url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftCheckoutSessionURLFromJSON(jsonValue));
    }

    /**
     */
    async giftsCheckoutSessionUrlRetrieve(requestParameters: GiftsCheckoutSessionUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftCheckoutSessionURL> {
        const response = await this.giftsCheckoutSessionUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsCreateRaw(requestParameters: GiftsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>> {
        if (requestParameters.Gift === null || requestParameters.Gift === undefined) {
            throw new runtime.RequiredError('Gift','Required parameter requestParameters.Gift was null or undefined when calling giftsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GiftToJSON(requestParameters.Gift),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftFromJSON(jsonValue));
    }

    /**
     */
    async giftsCreate(requestParameters: GiftsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift> {
        const response = await this.giftsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsDestroyRaw(requestParameters: GiftsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giftsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async giftsDestroy(requestParameters: GiftsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.giftsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async giftsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GiftFromJSON));
    }

    /**
     */
    async giftsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>> {
        const response = await this.giftsListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsReceiptUrlRetrieveRaw(requestParameters: GiftsReceiptUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftReceiptURL>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giftsReceiptUrlRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/{id}/receipt-url/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftReceiptURLFromJSON(jsonValue));
    }

    /**
     */
    async giftsReceiptUrlRetrieve(requestParameters: GiftsReceiptUrlRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftReceiptURL> {
        const response = await this.giftsReceiptUrlRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsReceivedListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/received/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GiftFromJSON));
    }

    /**
     */
    async giftsReceivedList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>> {
        const response = await this.giftsReceivedListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsRetrieveRaw(requestParameters: GiftsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giftsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftFromJSON(jsonValue));
    }

    /**
     */
    async giftsRetrieve(requestParameters: GiftsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift> {
        const response = await this.giftsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsSentListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gift>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/sent/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GiftFromJSON));
    }

    /**
     */
    async giftsSentList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gift>> {
        const response = await this.giftsSentListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsUpdateRaw(requestParameters: GiftsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gift>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling giftsUpdate.');
        }

        if (requestParameters.Gift === null || requestParameters.Gift === undefined) {
            throw new runtime.RequiredError('Gift','Required parameter requestParameters.Gift was null or undefined when calling giftsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GiftToJSON(requestParameters.Gift),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftFromJSON(jsonValue));
    }

    /**
     */
    async giftsUpdate(requestParameters: GiftsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gift> {
        const response = await this.giftsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async giftsValidateCodeCreateRaw(requestParameters: GiftsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GiftCodeValidationResult>> {
        if (requestParameters.GiftCodeValidation === null || requestParameters.GiftCodeValidation === undefined) {
            throw new runtime.RequiredError('GiftCodeValidation','Required parameter requestParameters.GiftCodeValidation was null or undefined when calling giftsValidateCodeCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/gifts/validate-code/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GiftCodeValidationToJSON(requestParameters.GiftCodeValidation),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GiftCodeValidationResultFromJSON(jsonValue));
    }

    /**
     */
    async giftsValidateCodeCreate(requestParameters: GiftsValidateCodeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GiftCodeValidationResult> {
        const response = await this.giftsValidateCodeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
