/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Gift
 */
export interface Gift {
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly redemption_code: string;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly sender_name: string;
    /**
     * Whether the sender should be presented as anonymous.
     * @type {boolean}
     * @memberof Gift
     */
    sender_is_anonymous?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly receiver_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly receiver_phone: string | null;
    /**
     * The name of the intended receiver.
     * @type {string}
     * @memberof Gift
     */
    intended_receiver_name: string;
    /**
     * The phone number of the intended receiver.
     * @type {string}
     * @memberof Gift
     */
    intended_receiver_phone: string;
    /**
     * A memo for use by the sender.
     * @type {string}
     * @memberof Gift
     */
    memo?: string;
    /**
     * 
     * @type {number}
     * @memberof Gift
     */
    gift_credit_months: number | null;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly gift_credit_amount: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    fundraiser_code?: string;
    /**
     * 
     * @type {string}
     * @memberof Gift
     */
    readonly fundraiser_team_name: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Gift
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Date}
     * @memberof Gift
     */
    readonly last_updated_at: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Gift
     */
    readonly purchased: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Gift
     */
    readonly purchased_at: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Gift
     */
    readonly claimed: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Gift
     */
    readonly claimed_at: Date | null;
}

/**
 * Check if a given object implements the Gift interface.
 */
export function instanceOfGift(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "redemption_code" in value;
    isInstance = isInstance && "sender_name" in value;
    isInstance = isInstance && "receiver_name" in value;
    isInstance = isInstance && "receiver_phone" in value;
    isInstance = isInstance && "intended_receiver_name" in value;
    isInstance = isInstance && "intended_receiver_phone" in value;
    isInstance = isInstance && "gift_credit_months" in value;
    isInstance = isInstance && "gift_credit_amount" in value;
    isInstance = isInstance && "fundraiser_team_name" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "last_updated_at" in value;
    isInstance = isInstance && "purchased" in value;
    isInstance = isInstance && "purchased_at" in value;
    isInstance = isInstance && "claimed" in value;
    isInstance = isInstance && "claimed_at" in value;

    return isInstance;
}

export function GiftFromJSON(json: any): Gift {
    return GiftFromJSONTyped(json, false);
}

export function GiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gift {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'redemption_code': json['redemption_code'],
        'sender_name': json['sender_name'],
        'sender_is_anonymous': !exists(json, 'sender_is_anonymous') ? undefined : json['sender_is_anonymous'],
        'receiver_name': json['receiver_name'],
        'receiver_phone': json['receiver_phone'],
        'intended_receiver_name': json['intended_receiver_name'],
        'intended_receiver_phone': json['intended_receiver_phone'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'gift_credit_months': json['gift_credit_months'],
        'gift_credit_amount': json['gift_credit_amount'],
        'fundraiser_code': !exists(json, 'fundraiser_code') ? undefined : json['fundraiser_code'],
        'fundraiser_team_name': json['fundraiser_team_name'],
        'created_at': (new Date(json['created_at'])),
        'last_updated_at': (new Date(json['last_updated_at'])),
        'purchased': json['purchased'],
        'purchased_at': (json['purchased_at'] === null ? null : new Date(json['purchased_at'])),
        'claimed': json['claimed'],
        'claimed_at': (json['claimed_at'] === null ? null : new Date(json['claimed_at'])),
    };
}

export function GiftToJSON(value?: Gift | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender_is_anonymous': value.sender_is_anonymous,
        'intended_receiver_name': value.intended_receiver_name,
        'intended_receiver_phone': value.intended_receiver_phone,
        'memo': value.memo,
        'gift_credit_months': value.gift_credit_months,
        'fundraiser_code': value.fundraiser_code,
    };
}

